import React from 'react';
import './App.css';
import TextComponent from "./TextComponent";
import ContactForm from "./ContactForm";

function App() {
  return (
    <div className="App">
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono&display=swap');
        </style>
        <div id={"components-div"}>
            <TextComponent id={"about"} filepath={"content/About.md"}/>
            <TextComponent id={"coding-portfolio"} filepath={"content/Portfolio.md"}/>
            <TextComponent id={"making-portfolio"} filepath={"content/Making-Portfolio.md"}/>
            {/* <ContactForm/> */}
        </div>
    </div>
  );
}

export default App;
