import {useEffect, useState} from "react"
import ReactMarkdown from 'react-markdown'

type props = {
    id: string;
    filepath: string;
}

function TextComponent(info : props) {
    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(info.filepath)
            .then((res) => res.text())
            .then((text) => setContent(text));
    }, [info.filepath]);

    return <div className={"page-component"} id={info.id}>
                <ReactMarkdown children={content}/>
            </div>
}

export default TextComponent